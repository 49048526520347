<template>
<div class="container-main">
	<!-- 定向邀请 -->
	<template v-if="type==1">
		<vxe-table :data="list" class="mytable-scrollbar forms"
			stripe highlight-hover-row
			size="small">
			<vxe-column type="seq" title="序号" width="65"></vxe-column>
			<vxe-column field="invite_contact_mobile" title="用户手机号"></vxe-column>
			<vxe-column field="join_date" title="提交时间"></vxe-column>
		</vxe-table>
	</template>
	<!-- end of 定向邀请 -->
	<!-- 公开邀请 -->
	<template v-if="type==2">
		<searchHeader :model="inputs" @search="searchBtn" class="search-box" />
		<vxe-table :data="list" class="mytable-scrollbar forms"
			stripe highlight-hover-row
			size="small">
			<vxe-column type="seq" title="序号" width="65"></vxe-column>
			<vxe-column field="invite_contact_mobile" title="用户手机号"></vxe-column>
			<vxe-column field="join_date" title="提交时间"></vxe-column>
		</vxe-table>
		<pageNumber  @changePage="changePage" :pages="pages"></pageNumber>
	</template>
	<!-- end of 公开邀请 -->
</div>
</template>

<script>
	import pageNumber from "@/components/phmAll/pageNumber.vue";
	import textOver from '@/components/phmAll/textOver';
	export default {
		components: {
			pageNumber,
			textOver,
		},
		data(){
			return {
				code:'',
				list:[],
				searchKey:{},
				type:0,
				pages: {
				  pageNum: 1,
				  pageSize: 10,
				  total: 10,
				},
				inputs:[
					{type:"input", field: "created_by",label: "用户手机号",value: ""},
					{type:"time", field: "created_at",label: "提交时间",value:[]},
				],
				
			}
		},
		created(){
			let query = this.$route.query;
			if(query.code){
				this.code = query.code;
				this.getListData();
			}
			if(query.type){
				this.type =Number(query.type);
			}
			
		},
		mounted(){
			this.$store.commit("updatePageNav", [
			  { icon: "fa fa-cog", name: "用户管理" },
			  { name: "邀请管理" },
			  { name: "邀请记录" },
			]);
		},
		methods: {
			searchBtn(data){
				console.log('data',data);
				this.searchKey = data;
				this.getListData();
			},
			changePage(val){
				if (val.type == "size") {
				  this.pages.pageSize = val.num;
				} else {
				  this.pages.pageNum = val.num;
				}
				this.getListData();
			},
			getListData(){
				let params = {
					invite_serial_number:this.code,
					invite_place:1,
					page_size:this.pages.pageSize,
					page:this.pages.pageNum,
				};
				if(!this.$utils.isEmpty(this.searchKey)){
					let search = this.searchKey;
					for(let key in search){
						if(search[key]){
							params[key] = search[key]
						}
					}
				}
				this.$axios.postJSON('/base/invite/log/list',params).then(res=>{
					if(res.code == 200){
						let data = res.data;
						if(data){
							if(data.list){
								this.list = data.list;
								this.pages.total = data.total_count
							}
						}else{
							this.list = [];
							if(this.type==1){
								// this.$message.warning("该用户暂未注册")
							}
						}
					}
				})
			},
		},
	}
</script>

<style scoped lang="scss">
.container-main{
	width:1200px;
	margin:0 auto;
	background-color: #FFFFFF;
	padding:20px;
	box-sizing: border-box;
}
.search-box{
	margin:20px 0;
}
</style>
